@font-face {
  font-family: 'carsandjobs-icons';
  src: url(/static/media/carsandjobs-icons.44b8b1ad.eot);
  src: url(/static/media/carsandjobs-icons.44b8b1ad.eot#iefix) format('embedded-opentype'),
       url(/static/media/carsandjobs-icons.71a56b2d.woff2) format('woff2'),
       url(/static/media/carsandjobs-icons.eaf92e48.woff) format('woff'),
       url(/static/media/carsandjobs-icons.5f03b3cd.ttf) format('truetype'),
       url(/static/media/carsandjobs-icons.ff61da61.svg#carsandjobs-icons) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'carsandjobs-icons';
    src: url('../font/carsandjobs-icons.svg?13490311#carsandjobs-icons') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "carsandjobs-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-cancel:before { content: '\E800'; } /* '' */
.icon-home:before { content: '\E801'; } /* '' */
.icon-star:before { content: '\E802'; } /* '' */
.icon-mail:before { content: '\E803'; } /* '' */
.icon-star-empty:before { content: '\E804'; } /* '' */
.icon-search:before { content: '\E805'; } /* '' */
.icon-check:before { content: '\E806'; } /* '' */
.icon-eye-off:before { content: '\E807'; } /* '' */
.icon-eye:before { content: '\E808'; } /* '' */
.icon-delete:before { content: '\E809'; } /* '' */
.icon-bookmark:before { content: '\E80A'; } /* '' */
.icon-user:before { content: '\E80B'; } /* '' */
.icon-video:before { content: '\E80C'; } /* '' */
.icon-bold:before { content: '\E80D'; } /* '' */
.icon-italic:before { content: '\E80E'; } /* '' */
.icon-link:before { content: '\E80F'; } /* '' */
.icon-picture:before { content: '\E810'; } /* '' */
.icon-edit:before { content: '\E811'; } /* '' */
.icon-briefcase:before { content: '\E812'; } /* '' */
.icon-megaphone:before { content: '\E813'; } /* '' */
.icon-refresh:before { content: '\E814'; } /* '' */
.icon-lock:before { content: '\E815'; } /* '' */
.icon-twitter:before { content: '\F099'; } /* '' */
.icon-facebook:before { content: '\F09A'; } /* '' */
.icon-filter:before { content: '\F0B0'; } /* '' */
.icon-list-ul:before { content: '\F0CA'; } /* '' */
.icon-list-ol:before { content: '\F0CB'; } /* '' */
.icon-strikethrough:before { content: '\F0CC'; } /* '' */
.icon-underline:before { content: '\F0CD'; } /* '' */
.icon-gplus:before { content: '\F0D5'; } /* '' */
.icon-linkedin:before { content: '\F0E1'; } /* '' */
.icon-angle-left:before { content: '\F104'; } /* '' */
.icon-angle-right:before { content: '\F105'; } /* '' */
.icon-angle-up:before { content: '\F106'; } /* '' */
.icon-angle-down:before { content: '\F107'; } /* '' */
.icon-quote-right:before { content: '\F10E'; } /* '' */
.icon-code:before { content: '\F121'; } /* '' */
.icon-doc-text-inv:before { content: '\F15C'; } /* '' */
.icon-youtube:before { content: '\F16A'; } /* '' */
.icon-education:before { content: '\F19D'; } /* '' */
.icon-file-word:before { content: '\F1C2'; } /* '' */
.icon-heading:before { content: '\F1DC'; } /* '' */
/* open-sans-regular - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: local('Open Sans Regular'), local('OpenSans-Regular'),
		 url(/static/media/open-sans-v15-latin-regular.cffb686d.woff2) format('woff2'), 
		 url(/static/media/open-sans-v15-latin-regular.bf2d0783.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* open-sans-600 - latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
		 url(/static/media/open-sans-v15-latin-600.223a277b.woff2) format('woff2'), 
		 url(/static/media/open-sans-v15-latin-600.1cd5320f.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
/* source-sans-pro-600 - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
		 url(/static/media/source-sans-pro-v11-latin-600.b3866d3f.woff2) format('woff2'), 
		 url(/static/media/source-sans-pro-v11-latin-600.08fdd6f1.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* source-sans-pro-700 - latin */
  @font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 700;
	src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
		 url(/static/media/source-sans-pro-v11-latin-700.f04441cf.woff2) format('woff2'), 
		 url(/static/media/source-sans-pro-v11-latin-700.d9d2bac7.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
/*!
 * Bootstrap Reboot v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.spinner {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

@keyframes lds-spinner {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.lds-spinner {
  position: relative; }

.lds-spinner div {
  left: 94px;
  top: 48px;
  position: absolute;
  -webkit-animation: lds-spinner linear 1s infinite;
  animation: lds-spinner linear 1s infinite;
  background: #164365;
  width: 12px;
  height: 24px;
  border-radius: 40%;
  -webkit-transform-origin: 6px 52px;
  transform-origin: 6px 52px; }

.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -0.916666666666667s;
  animation-delay: -0.916666666666667s; }

.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -0.833333333333333s;
  animation-delay: -0.833333333333333s; }

.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s; }

.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.666666666666667s;
  animation-delay: -0.666666666666667s; }

.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.583333333333333s;
  animation-delay: -0.583333333333333s; }

.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.416666666666667s;
  animation-delay: -0.416666666666667s; }

.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.333333333333333s;
  animation-delay: -0.333333333333333s; }

.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s; }

.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.166666666666667s;
  animation-delay: -0.166666666666667s; }

.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.083333333333333s;
  animation-delay: -0.083333333333333s; }

.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s; }

.lds-spinner {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px); }

.empty-state {
  text-align: center;
  width: 100%;
  min-height: 400px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #414142;
  font-size: 2rem; }
  .empty-state.inline {
    min-height: 0;
    padding: 12px;
    font-size: 1.2em; }

.primary-button {
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  background: none !important;
  border: none !important;
  display: inline-block;
  position: relative;
  margin: 6px;
  background: none;
  text-transform: uppercase;
  padding: 12px 35px;
  font-size: 14px;
  font-style: normal;
  letter-spacing: .1em;
  border-radius: 5px;
  text-decoration: none !important;
  text-align: center;
  cursor: pointer; }
  .primary-button:disabled, .primary-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5; }
  .primary-button.first {
    margin-left: 0; }
  .primary-button.last {
    margin-right: 0; }
  .primary-button.medium {
    padding-top: 8px;
    padding-bottom: 8px; }
  .primary-button.small {
    padding: 5px 18px;
    font-size: 12px; }
  .primary-button.dark .button-caption {
    color: white !important; }
  .primary-button.light .button-caption {
    color: #262626 !important; }
  .primary-button.light:hover .button-caption,
  .primary-button.light:hover .button-caption .icon {
    color: white !important; }
  .primary-button .button-caption {
    position: relative;
    color: white;
    text-decoration: none !important;
    font-weight: 400 !important;
    font-family: "Source Sans Pro", sans-serif; }
    .primary-button .button-caption,
    .primary-button .button-caption .icon {
      -webkit-transition: color .1s;
      transition: color .1s; }
    .primary-button .button-caption .left-icon,
    .primary-button .button-caption .right-icon {
      display: inline-block; }
    .primary-button .button-caption .left-icon {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
    .primary-button .button-caption .right-icon {
      -webkit-transform: translateX(50%);
              transform: translateX(50%); }
    .primary-button .button-caption.has-left-icon {
      margin-right: -.5em; }
    .primary-button .button-caption.has-right-icon {
      margin-left: -.5em; }
    .primary-button .button-caption.has-left-icon.has-right-icon {
      margin-left: 0;
      margin-right: 0; }
  .primary-button .button-background {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    background-color: white; }
    .primary-button .button-background-inner {
      position: absolute;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
              transform: translate(-50%, -50%) rotate(45deg);
      -webkit-transition: all .35s;
      transition: all .35s;
      -webkit-transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
              transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59); }
  .primary-button .button-border {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    border-radius: 5px;
    fill: none; }
    .primary-button .button-border rect {
      stroke: white;
      stroke-width: 1px;
      stroke-linecap: round;
      opacity: .7;
      fill: rgba(218, 38, 49, 0);
      -webkit-transition: fill .05s, stroke 1s;
      transition: fill .05s, stroke 1s; }
      .primary-button .button-border rect.hover-border {
        stroke: transparent;
        stroke-width: 1px;
        fill: rgba(38, 38, 38, 0.25);
        opacity: .9; }
  .primary-button:active {
    z-index: 1; }
    .primary-button:active:before {
      background-color: black; }
    .primary-button:active .button-caption,
    .primary-button:active .button-caption .icon {
      color: white !important; }
    .primary-button:active .button-background {
      -webkit-transition: none;
      transition: none; }
  .primary-button:hover .button-background {
    -webkit-transition: background-color .1s .2s;
    transition: background-color .1s .2s; }
    .primary-button:hover .button-background-inner {
      left: 50% !important;
      top: 50% !important; }
  .primary-button .button-background {
    border-color: #da2631; }
    .primary-button .button-background-inner {
      background-color: #da2631; }
  .primary-button:hover .button-border rect.hover-border {
    stroke: #da2631; }
  .primary-button:hover .button-background {
    background-color: #da2631; }
  .primary-button:active .button-border rect {
    fill: #da2631; }
  .primary-button:active .button-background {
    border-color: #a61c25;
    background-color: #a61c25; }
    .primary-button:active .button-background:after {
      background-color: #a61c25; }
  .primary-button.facebook .button-background {
    border-color: #4058A9; }
    .primary-button.facebook .button-background-inner {
      background-color: #4058A9; }
  .primary-button.facebook:hover .button-border rect.hover-border {
    stroke: #4058A9; }
  .primary-button.facebook:hover .button-background {
    background-color: #4058A9; }
  .primary-button.facebook:active .button-border rect {
    fill: #4058A9; }
  .primary-button.facebook:active .button-background {
    border-color: #2f417d;
    background-color: #2f417d; }
    .primary-button.facebook:active .button-background:after {
      background-color: #2f417d; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.15;
  margin-bottom: .7em; }

h1 {
  font-family: "Open Sans", sans-serif;
  font-size: 3em;
  font-weight: 400;
  text-transform: none; }

h2 {
  font-size: 1.5em;
  text-transform: uppercase; }

h2, h3, h4, h5, h6 {
  color: #164365; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1.17em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

html {
  overflow: hidden; }
  @media (min-width: 769px) {
    html {
      overflow: visible;
      min-height: 100vh; } }

body {
  width: 100%;
  background-color: #414142; }

#root {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-justify-content: stretch;
          justify-content: stretch;
  min-height: 100%;
  max-width: 1920px; }

body, #root {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0 auto; }
  @media (min-width: 769px) {
    body, #root {
      position: static;
      height: auto;
      min-height: 100vh; } }

html {
  font-size: 10px;
  /* @media (max-width: $screen-md-max) {
			font-size: 9px;
		} */ }

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  color: #262626; }

p {
  margin-bottom: 1em; }

b, strong {
  font-weight: 600; }

a {
  text-decoration: none;
  color: #da2631 !important; }
  a:hover, a:active {
    text-decoration: underline; }
  a:active {
    color: rgba(218, 38, 49, 0.7) !important; }

blockquote {
  background-color: #e2e0e0;
  padding: 12px 24px;
  font-style: italic; }

table {
  border: 1px solid #e2e0e0;
  background-color: white; }

tr {
  background-color: white; }

td, th {
  border: 1px solid #e2e0e0;
  padding: 6px 12px; }
  td.min-width, th.min-width {
    width: 1%; }
  td.align-center, th.align-center {
    text-align: center; }

th {
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #164365;
  font-weight: 600; }

thead > tr {
  color: white;
  font-family: "Source Sans Pro", sans-serif; }

tbody > tr:nth-child(even) > td {
  background-color: rgba(226, 224, 224, 0.5); }

tbody > tr:hover > td, tbody > tr:active > td {
  background-color: rgba(22, 67, 101, 0.3); }

hr {
  border: 1px solid #e2e0e0;
  margin: 24px 0; }

.icon-angle-left:before {
  margin-left: .1em; }

.icon-angle-right:before {
  margin-right: .1em; }

@media (max-width: 640px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 641px) and (max-width: 768px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 769px) and (max-width: 1024px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1025px) {
  .hidden-lg {
    display: none !important; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

