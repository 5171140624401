.empty-state {
  text-align: center;
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #414142;
  font-size: 2rem; }
  .empty-state.inline {
    min-height: 0;
    padding: 12px;
    font-size: 1.2em; }
